import { ConsoleEngine, Logging, NgLogEngine } from '@nextgis/logging';

import pkg from '../../package.json';

const logger = new Logging({
  eachLog: (msg, opt = {}) => {
    opt.data = opt.data || {};
    opt.data.version = pkg.version;
    return opt;
  },
  engines: [
    new ConsoleEngine({ enabled: process.env.NODE_ENV === 'development' }),
    new NgLogEngine({
      clientId: '864f0876-9367-4ee7-9b93-140a657ebc36',
      nglogUrl: 'https://nglog.staging.nextgis.com',
      stopOnConnectionError: true,
      enabled: process.env.NODE_ENV !== 'development',
    }),
  ],
});

export default logger;
