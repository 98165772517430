import { ConfigProvider as AntdConfigProvider } from 'antd';
import ruRu from 'antd/locale/ru_RU';

import { PrpMap } from '../burpriroda-prp/src/components/PrpMap';
import { ConfigProvider } from '../burpriroda-prp/src/ConfigProvider';

import logger from './services/logger';
import { baseUrl, identifySourceItems, WEBMAP_ID } from './config';

export default function App() {
  logger.info('The app is started', {
    operationId: 'app-started',
  });
  return (
    <AntdConfigProvider locale={ruRu}>
      <ConfigProvider
        identifySourceItems={identifySourceItems}
        baseUrl={baseUrl}
        webMapId={WEBMAP_ID}
        customReportSheets={[
          {
            name: 'Реквизиты',
            loader: async ({ dt }) =>
              import('./report/createDetailsSheet').then(
                ({ createDetailsSheet }) => createDetailsSheet(dt),
              ),
          },
        ]}
      >
        <PrpMap />
      </ConfigProvider>
    </AntdConfigProvider>
  );
}
